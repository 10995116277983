import React from "react";

import { DesprogramadosBanner } from '../components/desprogramadosBanner';
import  { DesprogramadosGame }  from '../components/desprogramadosGame';
import { DesprogramadosSolutions }  from '../components/desprogramadosSolutions';

function Desprogramados() {

    return (
        <>
            <DesprogramadosBanner />
            <DesprogramadosGame />
            <DesprogramadosSolutions />
        </>
    )
}

export default Desprogramados;