import React from "react";


import ficha1 from '../assets/images/desprogramados/ficha1.svg';
import ficha2 from '../assets/images/desprogramados/ficha2.svg';
import ficha3 from '../assets/images/desprogramados/ficha3.svg';
import ficha4 from '../assets/images/desprogramados/ficha4.svg';
import ficha5 from '../assets/images/desprogramados/ficha5.svg';

const Card = ({ imgSrc, number, title, description }) => (
    <div className="w-full mmd:w-[35%] mlg:w-[18%] px-2 py-8 rounded-3xl mt-10 bg-white shadow-sm transform transition duration-300 hover:translate-y-[-10px] hover:shadow-lg">
        <img 
            src={imgSrc} 
            alt={title} 
            className="w-[95%] h-auto mx-auto mt-[-3rem]" 
        />
        <h3 className="color6 text-xl text-left font-bold  mt-6"><b className="font-bold text-5xl mr-2 text-1">{number}</b>{title}</h3>
        <p className="text-justify text-lg text-gray-600 mt-4 px-2">{description}</p>
   
    </div>
);

export function DesprogramadosGame() {
    return (
        <>
            <section id="game" className="w-screen flex bg-orange-50">
                <div className="mt-20 w-full mb-20">
                    <h2 className="text-center text-4xl text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-3xl">
                    Potencia tu empresa y transforma las aulas
                    <br />
                    ¿Cómo funciona?
                       
                    </h2>

                    <div className="w-[95%] mx-auto mt-14 flex flex-col mmd:flex-wrap mmd:flex-row justify-center gap-2 mmd:gap-2">
                        <Card 
                            imgSrc={ficha1} 
                            number="1"
                            title="Definimos juntos la experiencia" 
                            description="Empresas nos convocan para diseñar una actividad de team building o evento corporativo con impacto social. Tras una consultoría inicial, creamos una experiencia ajustada a tus necesidades." 
                      
                        />
                        <Card 
                            imgSrc={ficha2} 
                            number="2"
                            title="Te facilitamos los recursos (kits)" 
                            description="Enviamos un kit completo con todo lo necesario para la actividad y brindamos soporte detallado para su implementación en la empresa, garantizando una experiencia sin complicaciones." 
                   
                        />
                        <Card 
                            imgSrc={ficha3} 
                            number="3"  
                            title="Disfruta la actividad o evento" 
                            description="Los equipos o participantes viven una experiencia única, fortaleciendo la cohesión y compartiendo un momento significativo." 
                      
                        />
                        <Card 
                            imgSrc={ficha4} 
                            number="4"
                            title="Recibe feedback y emití insignias" 
                            description="Al finalizar, cada participante completa un formulario que mide el impacto de la actividad, y recibe una insignia digital en reconocimiento a las habilidades desarrolladas." 
                  
                        />

                        <Card 
                            imgSrc={ficha5} 
                            number="5"
                            title="Dona tu kit para potenciar un aula" 
                            description="Al concluir, los kits se donan a una institución educativa elegida por los participantes, llevando el aprendizaje de programación y pensamiento computacional a las aulas sin necesidad de pantallas." 
                      
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
