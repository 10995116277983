import React, {useState} from "react";

export function DesprogramadosSolutions() {
    const [currentSolution, setCurrentSolution] = useState(1);
   

    return (
        <>
            <section id="game" className="flex bg-orange-50 w-[90%] rounded-3xl mx-auto mt-10">
                <div className="mt-20 w-full mb-20">
                    <h2 className="text-center text-4xl text-gray-800 font-semibold w-full mmd:w-[85%] mx-auto md:2-[80%] md:text-3xl">
                    ¿Cómo te suma desprogramados?
                    </h2>

                    <div className="mt-10 flex justify-center space-x-4 mb-4 rounded-full bg-[#83abb383] w-1/4 mx-auto">
                    <button
                            className="px-10 py-2 font-bold text-white  rounded-full hover:bg-[#55b2c9] focus:bg-[#55b2c9]"
                            onClick={() => setCurrentSolution(1)}
                            style={{backgroundColor: currentSolution === 1 ? '#55b2c9' : ''}}
                        >
                        EMPRESAS
                        </button>
                        <button
                            className="px-10 py-2 font-bold text-white  rounded-full hover:bg-[#55b2c9] focus:bg-[#55b2c9]"
                            onClick={() => setCurrentSolution(2)}
                            style={{backgroundColor: currentSolution === 2 ? '#55b2c9' : ''}}
                        >
                            ESCUELAS
                        </button>
                    </div>  

                

                </div>
            </section>
        </>
    );
}